import * as React from "react"
import SharedHeader from "../components/general/components/SharedHeader"
import AddressPage from "../components/profile/pages/AddressPage"


// markup
const Address = (props) => {
    return (
        <React.Fragment>
            <SharedHeader />
            <AddressPage {...props} />
        </React.Fragment>
    )
}

export default Address
