import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { forEach, get, isPlainObject } from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import { loginSuccessful } from "../../../redux/actions/user-actions";

// markup
const AddressForm = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.editMode === true && isPlainObject(props.data)) {
      form.setFieldsValue(props.data);
    } else if (props.editMode === false) {
      form.resetFields();
    }
  }, [props.data, props.editMode]);

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => {
        if (props.onSubmit) {
          props.onSubmit(values, props.editMode);
          form.resetFields();
        }
      })
      .catch((err) => {
        forEach(get(err, "errorFields"), (item) => {
          message.error(get(item, "errors[0]"));
        });
      });
  }

  return (
    <React.Fragment>
      <Form form={form} style={{ color: "white" }}>
        <div className="text-base">
          {/* 提币地址 <br />
          Withdrawal Address */}
          {t("withdrawalAddress", sourceKey.profile)}
        </div>
        <div className="my-3">
          <Form.Item
            style={{ margin: 0 }}
            name={`address`}
            rules={[
              {
                required: true,
                message: (
                  <React.Fragment>
                    {/* 请填写提币地址
                    <br /> */}
                    {/* Withdrawal Address is required. */}
                    {t("withdrawalAddressRequired", sourceKey.profile)}
                  </React.Fragment>
                ),
              },
            ]}
          >
            <Input className="w-full input-border" />
          </Form.Item>
        </div>
        <div className="text-base">
          {/* 备注 <br /> */}
          {t("remark", sourceKey.profile)}
        </div>
        <div className="my-3">
          <Form.Item
            style={{ margin: 0 }}
            name={`remark`}
            rules={[
              {
                required: true,
                message: (
                  <React.Fragment>
                    <br />
                    {/* Remark is required. */}
                    {t("remark", sourceKey.profile)}
                  </React.Fragment>
                ),
              },
            ]}
          >
            <Input className="w-full input-border" />
          </Form.Item>
        </div>
        <div className="mt-10">
          <Button
            className="main-button-color input-border"
            block
            onClick={(e) => {
              handleSubmit();
            }}
          >
            {t("submit")}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddressForm);
