import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, message, Modal, Spin, Table } from "antd";
import { cloneDeep, isPlainObject } from "lodash";
import get from "lodash.get";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { connect } from "react-redux";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import createProfileWalletAddress from "../../../newApi/profile/createProfileWalletAddress";
import deleteProfileWalletAddress from "../../../newApi/profile/deleteProfileWalletAddress";
import editProfileWalletAddress from "../../../newApi/profile/editProfileWalletAddress";
import getProfiles from "../../../newApi/profile/getProfiles";
import { loginSuccessful } from "../../../redux/actions/user-actions";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import AddressForm from "../components/AddressForm";

// markup
const RobotPage = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [formEditMode, setFormEditMode] = useState(false);
  const accessKey = get(props.user, "accessKey");
  const user = get(props, "user.user");
  const profile = get(user, "profile");

  useEffect(() => {
    if (!confirmVisible) {
      setSelectedData({});
    }
  }, [confirmVisible]);

  useEffect(() => {
    if (!formVisible) {
      setSelectedData({});
    }
  }, [formVisible]);

  useEffect(() => {
    getAddressListAPI();
  }, []);

  function getAddressListAPI() {
    /* getAddressList(accessKey).then(res => {
            if (get(res, 'data.status') === '200') {
                setDataSource(get(res, 'data.data') || []);
            } else {
                message.error(get(res, 'data.msg') || get(res, 'data.msgCode'))
            }
        }).catch(err => {
            setLoading(false);
        }); */

    let value = {
      _id: get(profile, "_id"),
    };

    getProfiles(1, 0, {
      _id: get(profile, "_id"),
    })
      .then((res) => {
        const profile_data = get(res, "data[0]");
        // console.log("profile_data", profile_data, profile_data?.addressList);

        setDataSource(get(profile_data, "addressList") || []);
      })
      .catch((err) => {
        message.error(t("userNotFound", sourceKey.asset));
      });
  }

  function createAddressAPI(values) {
    if (isPlainObject(values)) {
      setLoading(true);
      createProfileWalletAddress({
        profileId: get(profile, "_id"),
        address: values?.address,
        remark: values?.remark,
      })
        .then((res) => {
          setLoading(false);
          const addressListAll = get(res, "data.profile.addressList");
          message.success(t("added", sourceKey.profile));
          setDataSource(addressListAll || []);
        })
        .catch((err) => {
          message.error(err?.message);
          setLoading(false);
        });
    }
  }

  function editAddressAPI(values, id) {
    if (isPlainObject(values) && id) {
      //console.log("values", values);
      let edited_data = cloneDeep(dataSource);
      let edited_dataIndex = edited_data.findIndex((u) => u._id === id);
      edited_data[edited_dataIndex] = values;

      editProfileWalletAddress({
        profileId: get(profile, "_id"),
        _id: id,
        address: values?.address,
        remark: values?.remark,
      })
        .then((res) => {
          // console.log(res);
          setLoading(false);
          const addressListAll = get(res, "data.profile.addressList");
          message.success(t("edited", sourceKey.profile));
          setDataSource(addressListAll || []);
        })
        .catch((err) => {
          message.error(err?.message, 2);
          // message.error("User not found");
        });
    }
  }

  function deleteAddressAPI(id) {
    if (id) {
      setLoading(true);
      let original_data = cloneDeep(dataSource);

      let deleted_data = original_data.filter((u) => u._id !== id);

      let data = {
        addressList: deleted_data,
      };

      deleteProfileWalletAddress({
        profileId: get(profile, "_id"),
        _id: id,
      })
        .then((res) => {
          setLoading(false);
          const addressListAll = get(res, "profile.addressList");
          message.success(t("deleted", sourceKey.profile));
          setDataSource(addressListAll || []);
        })
        .catch((err) => {
          message.error(err?.message);
          setLoading(false);
        });

      /* deleteAddress(id, accessKey)
        .then((res) => {
          setLoading(false);
          if (get(res, "data.status") === "200") {
            message.success("已删除");
            setDataSource(
              filter(dataSource, (item) => {
                return get(item, "tid") != id;
              })
            );
          } else {
            message.error(get(res, "data.msg") || get(res, "data.msgCode"));
          }
        })
        .catch((err) => {
          setLoading(false);
        }); */
    }
  }

  const columns = [
    {
      title: (
        <React.Fragment>
          <div className="text-sm text-center">
            {/* 地址 <br /> */}
            {t("address", sourceKey.profile)}
          </div>
        </React.Fragment>
      ),
      dataIndex: "address",
      width: 250,
      key: "address",
      render: (value, record) => {
        return (
          <div className="text-xs text-center">
            {value?.replace(
              value?.substring(7, value.length - 5),
              "**************"
            )}
          </div>
        );
      },
    },
    {
      title: (
        <React.Fragment>
          <div className="text-sm  text-center">
            {/* 备注 <br /> */}
            {t("remark", sourceKey.profile)}
          </div>
        </React.Fragment>
      ),
      dataIndex: "remark",
      key: "remark",
      render: (value, record) => {
        return <div className="text-xs text-center">{value}</div>;
      },
    },
    {
      title: (
        <React.Fragment>
          <div className="text-sm  text-center">
            {/* 操作 <br /> */}
            {t("action", sourceKey.profile)}
          </div>
        </React.Fragment>
      ),
      render: (value, record) => {
        return (
          <React.Fragment>
            <div className="flex items-center justify-center">
              <EditFilled
                className="mr-3 success-color"
                onClick={(e) => {
                  setFormVisible(true);
                  setFormEditMode(true);
                  setSelectedData(record);
                }}
              />
              <DeleteFilled
                className="danger-color"
                onClick={(e) => {
                  setConfirmVisible(true);
                  setSelectedData(record);
                }}
              />
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            showBack
            suffix={
              <div className="justify-end flex items-center">
                {t("withdrawalAddress", sourceKey.profile)}
              </div>
            }
          >
            <div className="py-5 px-3">
              <div className="flex justify-center items-center my-3">
                <Button
                  className="main-button-color main-button-padding"
                  shape="round"
                  onClick={(e) => {
                    setFormEditMode(false);
                    setFormVisible(true);
                  }}
                >
                  {/* 新增新地址 */}
                  {t("createNewAddress", sourceKey.profile)}
                </Button>
              </div>
            </div>
            <Scrollbars style={{ height: 500 }}>
              <div className="m-3">
                <Table
                  size={"small"}
                  scroll={{ x: 600 }}
                  columns={columns}
                  pagination={false}
                  dataSource={dataSource}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {}, // click row
                      onDoubleClick: (event) => {}, // double click row
                      onContextMenu: (event) => {}, // right button click row
                      onMouseEnter: (event) => {}, // mouse enter row
                      onMouseLeave: (event) => {}, // mouse leave row
                    };
                  }}
                ></Table>
              </div>
            </Scrollbars>
          </NavHeader>
        </Spin>
      </Layout>

      <ConfirmationModal
        visible={confirmVisible}
        onClose={() => {
          setConfirmVisible(false);
        }}
        onOk={() => {
          if (get(selectedData, "_id")) {
            deleteAddressAPI(get(selectedData, "_id"));
            setConfirmVisible(false);
          }
        }}
      ></ConfirmationModal>

      <Modal
        wrapClassName="no-padding-modal-body modal-body-background-transparent"
        centered
        maskClosable
        width="60%"
        mask
        footer={null}
        visible={formVisible}
        onCancel={() => {
          setFormVisible(false);
        }}
      >
        <div className="p-8 rounded-3xl lightgreybox-bg-color">
          <AddressForm
            data={selectedData}
            editMode={formEditMode}
            onSubmit={(values, editMode) => {
              if (!editMode) {
                if (isPlainObject(values)) {
                  //console.log("add address", values);
                  createAddressAPI(values);
                  setFormVisible(false);
                }
              } else {
                if (isPlainObject(values)) {
                  editAddressAPI(values, get(selectedData, "_id"));
                  setFormEditMode(false);
                  setFormVisible(false);
                }
              }
            }}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginSuccessful,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
